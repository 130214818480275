<template>
  <base-layout-two
    page-title="New Purchasable"
    page-default-back-link="/purchasables"
  >
    <div class="padding-8">
      <v-form @submit="onSubmit">
        <base-card title="Purchasable Details">
          <ion-grid>
            <ion-row>
              <!-- Property -->
              <ion-col>
                <base-input label-text="Property *">
                  <v-field
                    name="property"
                    v-slot="{ field }"
                    v-model="purchasable.property_id"
                    :rules="required"
                  >
                    <select v-bind="field" class="regular-select">
                      <option
                        v-for="property in properties"
                        :key="property.id"
                        :value="property.id"
                      >
                        {{ property.name }}
                      </option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="property" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>

            <ion-row>
              <!-- Name -->
              <ion-col size="12">
                <base-input label-text="Name *">
                  <v-field
                    name="name"
                    v-slot="{ field }"
                    v-model="purchasable.name"
                    :rules="required"
                  >
                    <ion-input
                      name="name"
                      v-bind="field"
                      placeholder=""
                      type="text"
                      autocapitalize="words"
                      autocorrect
                    >
                    </ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="name" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Description -->
              <ion-col size="12">
                <base-input label-text="Description *">
                  <v-field
                    name="description"
                    v-slot="{ field }"
                    v-model="purchasable.description"
                    :rules="required"
                  >
                    <ion-input
                      name="description"
                      v-bind="field"
                      placeholder=""
                      type="text"
                      autocapitalize
                      autocorrect
                    >
                    </ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="description" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Price Retail -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Price Retail *">
                  <v-field
                    name="price_retail"
                    v-slot="{ field }"
                    v-model="purchasable.price_retail"
                    :rules="required"
                  >
                    <ion-input
                      name="price_retail"
                      v-bind="field"
                      placeholder="0.00"
                      type="number"
                    ></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="price_retail" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Tax Type -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Tax Type *">
                  <v-field
                    name="tax_type"
                    v-slot="{ field }"
                    v-model="purchasable.tax_type"
                    :rules="required"
                  >
                    <select v-bind="field" class="regular-select">
                      <option value="exempt">Exempt</option>
                      <option value="sales">Sales</option>
                      <option value="hotel">Hotel</option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="tax_type" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-button type="submit" expand="block" class="margin-y-16">
            Save
          </ion-button>
        </base-card>
      </v-form>
    </div>
  </base-layout-two>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonInput, IonButton } from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      purchasable: {
        tax_type: "exempt",
      },
      properties: [],
      required: yup.string().required(),
    };
  },

  async ionViewWillEnter() {
    await this.fetchProperties();
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async fetchProperties() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/properties`)
        .then((response) => {
          this.properties = response.data.properties;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async onSubmit() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/purchasables`,
        data: this.purchasable,
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.purchasable = response.data.purchasable;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({
            message: "Purchasable Created",
            color: "secondary",
          });

          this.$router.replace(`/purchasables/${this.purchasable.id}`);
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>